import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { useState } from 'react';

import {
  CentresDataForCentresSection,
  ServicesDataForDropdown,
} from '../../../components/CentresSection/CentresSection';
import { Stack } from '../../../components/Core';
import Select from '../../../components/Form/Select';
import StyledLink from '../../../components/StyledLink';
import ThemedButton from '../../../components/ThemedButton';
import { IWordpressCTA } from '../../../types/wordpress/blocks';
import { formatSelectOptions, syncWith } from '../../../utils/options';
import { __ } from '../../../utils/helpers/i18n';

const KinequickForm: FCWithClassName<{
  data: any;
  centrePlaceholder: string | undefined;
  cta?: IWordpressCTA | undefined;
  ctaTelephone?: { readonly label: string; readonly telephone: string };
  customLabelRDV?: string;
  servicePlaceholder: string | undefined;
  defaultService?: ServicesDataForDropdown;
  hideService?: boolean;
  defaultCentre?: CentresDataForCentresSection;
  hideCentre?: boolean;
  lang?: string;
}> = ({
  data,
  centrePlaceholder,
  cta,
  ctaTelephone,
  customLabelRDV = 'Prendre rendez vous en ligne',
  servicePlaceholder,
  defaultService,
  hideService = false,
  defaultCentre,
  hideCentre = false,
  lang = 'fr',
}) => {
  const [selectedService, setSelectedService] = useState<
    ServicesDataForDropdown | undefined
  >(defaultService || undefined);
  const [selectedCentre, setSelectedCentre] = useState<
    CentresDataForCentresSection | undefined
  >(defaultCentre || undefined);
  const ctaWithNavigationProps = {
    ...cta,
    navigationProps: {
      idCentre: selectedCentre?.slug,
      kinequickSpeciality: selectedService?.slug,
      kinequickType: selectedService?.informationsService?.kinequick?.type,
      services: [selectedService?.slug],
    },
  };

  const selectCentres = formatSelectOptions(
    syncWith(data ? data : [], selectedService?.slug || '', 'service'),
    'place'
  );
  const selectServices = formatSelectOptions(
    syncWith(data ? data : [], selectedCentre?.slug || '', 'place'),
    'service'
  );

  const kinequickServiceButton = (
    <StyledLink
      to={ctaWithNavigationProps.internalLink?.uri}
      state={ctaWithNavigationProps.navigationProps}
      className="w-fit"
    >
      <ThemedButton size="sm">{customLabelRDV}</ThemedButton>
    </StyledLink>
  );

  // const serviceContactFormButton = (
  //   <StyledLink
  //     to={ctaWithNavigationProps.internalLink?.uri}
  //     state={ctaWithNavigationProps.navigationProps}
  //     className="no-underline w-fit"
  //   >
  //     <ThemedButton size="sm" className="decoration-0">
  //       {cta.titre}
  //     </ThemedButton>
  //   </StyledLink>
  // );

  // const serviceContactFormButton = (
  //   <AnchorLink
  //     to={`${selectedService?.uri}?centre=${selectedCentre?.slug}#appointment`}
  //     className="no-underline w-fit"
  //   >
  //     <ThemedButton size="sm" className="decoration-0">
  //       {cta.titre}
  //     </ThemedButton>
  //   </AnchorLink>
  // );
  // const FormCTA =
  //   !selectedService || selectedService?.informationsService?.kinequick?.speciality
  //     ? kinequickServiceButton
  //     : serviceContactFormButton;

  return (
    <Stack className="relative max-w-sm mt-8">
      {((selectedCentre && !defaultCentre) ||
        (selectedService && !defaultService)) && (
        <div>
          <button
            className="absolute top-0 left-0 flex items-center w-auto gap-1 pt-2 pb-1 pl-2 pr-3 mb-1 text-left transition -translate-y-[calc(100%_+_8px)] bg-gray-700 hover:bg-gray-700/50"
            type="button"
            onClick={() => {
              // Reset state with taking into account the default values
              setSelectedCentre(defaultCentre || undefined);
              setSelectedService(defaultService || undefined);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="flex-shrink-0 w-5 h-5 -mt-1 text-primary-500"
              viewBox="0 0 384 512"
            >
              <path
                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3l105.4 105.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256l105.3-105.4z"
                fill="currentColor"
              />
            </svg>
            <span className="font-bold text-white uppercase font-heading">
              {__('Réinitialiser', lang)}
            </span>
          </button>
        </div>
      )}

      {selectCentres && !hideCentre && (
        <Select
          className="text-base !mb-[0.5rem]"
          type="select"
          name="centre"
          id="centre"
          placeholder={centrePlaceholder}
          onChange={(e) =>
            setSelectedCentre(
              selectCentres?.find(
                (centre: any) => centre.slug === e.target.value
              ) || undefined
            )
          }
          value={selectedCentre?.slug}
          options={selectCentres?.map((centre: any) => {
            if (!centre) return undefined;

            return {
              checked: false,
              label: centre.title,
              name: centre.slug,
            };
          })}
        />
      )}

      {selectServices && !hideService && (
        <Select
          className="text-base !mb-[0.5rem]"
          id="service"
          type="select"
          name="service"
          onChange={(e) => {
            setSelectedService(
              selectServices?.find(
                (service: any) => service.slug === e.target.value
              )
            );
          }}
          value={selectedService?.slug}
          options={selectServices?.map((service: any) => {
            if (!service) return undefined;
            return {
              checked: false,
              label: service.title,
              name: service.slug,
            };
          })}
          placeholder={servicePlaceholder}
        />
      )}

      {kinequickServiceButton}

      {/*ctaTelephone?.telephone && ctaTelephone?.label && (
        <a
          className="no-underline border-b-0 w-fit"
          href={`tel:${ctaTelephone.telephone}`}
        >
          <ThemedButton size="sm" className="!mt-[0.5rem]">
            {ctaTelephone.label}
          </ThemedButton>
        </a>
      )*/}
    </Stack>
  );
};

export default KinequickForm;
